<template>
  <div class="container kuan">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="container kuan flex64">
        <div class="main">
          <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

            <!-- <div class="kuaixuan_wrap" v-if="dop==1">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData.length-1,disable:item.disable}" v-for="(item,iIndex) in selNumberData" :key="item.title">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>

                
                  </div>

                </section>

              </section>

            </div>
            <div class="kuaixuan_wrap p2" v-if="dop==2">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData1.length-1}" v-for="(item,iIndex) in selNumberData1" :key="item.title">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel1(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>
 
                  </div>

                </section>

              </section>

            </div> -->
          </div>
          <!-- 注单号码 -->
          <div class="player listmain  tuoduopeng" :class="dop==1?'h2zd_l':'h2zd_h'">
            <div class="data">
              <h3>注单号码 <span>共 <em class="red">{{resultData.length}} </em>种组合 </span></h3>

              <!-- <div class="dantuo-li">

          </div> -->
              <div class="dantuo-sc " :class="dop==1?'h2zd_ld':'h2zd_hd'">
                <div>
                  <span v-for="(item,index) in resultData" :key="index">{{item.label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="other">
          <!-- <div class="zezhao" v-if="zezhao||weihu"></div> -->
          <div class="right-com">

            <div style="height:1px"></div>
            <section class="other_row">
              <span class="font_bold">定位置</span>
              <span class="inlineblock" style="width:3px"></span>
              <input type="radio" v-model="dcq" value="chu" @change="dcqChange" class="t2">除
              <input type="radio" v-model="dcq" value="qu" @change="dcqChange" class="t2">取

            </section>
            <section class="other_row"  v-if="dop==1">
         
              <div v-for="(item,index) in selNumberInput0" style="display:inline-block" :key="item.title+111">
              <span class="font_bold">{{ item.title }}</span>
              <span class="inlineblock"  style="width:3px"></span>
              <input class="w80"   @input="removeDuplicates($event,item)" type="number" v-model="item.numbervalue">
              <span class="inlineblock"  style="width:3px"></span>
            </div>
         
           
            </section>
            
        
           
          
            <section  >
              
              <div  class="btnaa">
                <span  class="button" @click="filterYZDData">生成</span>
                <span  class="button" @click="resetyZDCondition">重置</span>
              </div>
              </section>

          </div>
        </div>
      </div>
       

      <yushe />
    </div>
    
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import kxmixins from "../mixins/kxmixinspl3";

import mixins from "../mixins/mainMixins";
export default {
  name: "1zdkx",
  props: [""],
  mixins: [mixins,kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'1zdw',
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        
      ],
      yzdData:[],
     
      labelArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      labelArr1:['0XX','1XX','2XX','3XX','4XX','5XX','6XX','7XX','8XX','9XX'],
      labelArr2:['X0X','X1X','X2X','X3X','X4X','X5X','X6X','X7X','X8X','X9X'],
      labelArr3:['XX0','XX1','XX2','XX3','XX4','XX5','XX6','XX7','XX8','XX9'],
    
    };
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
           
              if (item.money) {
                let obj = {
                  label: item.label,
                  Odds: item.Odds,
                  title: '一字定',
                  id: item.ResultID,
                  money: Number(item.money),
                };
                arr.push(obj);
              
              }
            
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        this.yzdData=[]
        // 佰位
        let arr1 = this.filterData(resAArr, 3619, 3628, this.labelArr1);
        // this.rowsData[0].arr = arr1;
        // 拾位
        let arr2 = this.filterData(resAArr, 3629, 3638, this.labelArr2);
        // this.rowsData[1].arr = arr2;
        // 个位
        let arr3 = this.filterData(resAArr, 3639, 3648, this.labelArr3);
        // this.rowsData[2].arr = arr3;
  this.yzdData=[arr1,arr2,arr3]
 
        this.$forceUpdate();
      },
    },
  },

  created() {},

  methods: {
    
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.type-f3d .greenball {
  /* background-image: url("../../assets/3d.png"); */
}
</style>